<template>
    <div class="user">
      <!-- 面包屑导航 -->
      <el-card>
        <!-- 头 -->
        <el-row :gutter="20">
          <el-col :span="5">
            <el-input placeholder="请输入内容" v-model="getInfo.info">
              <el-button slot="append" icon="el-icon-search"></el-button>
            </el-input>
          </el-col>    
          <el-tooltip
            class="item"
            effect="dark"
            content="增加分类"
            placement="top-start"
          >
            <el-button type="primary" @click="addClassBtn">增加分类</el-button>
          </el-tooltip>

        </el-row>
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          v-if="tableData"
        >
        <!-- <el-table-column prop="status" align="center" label="分类类型">
            <template slot-scope="scope">
              <div v-if="scope.row.classification_type==1">文章</div>
              <div v-if="scope.row.classification_type==2">美食</div>
              <div v-if="scope.row.classification_type==3">住宿</div>
              <div v-if="scope.row.classification_type==4">学院</div>
            </template>
          </el-table-column> -->
          
          <el-table-column align="center" prop="classification_name" label="分类名称">
          </el-table-column>
          <el-table-column align="center" prop="status" label="状态">
            <template slot-scope="scope">
              <el-switch
                @change="
                  changeState(
                    scope.row.classification_id,
                    scope.row.classification_status
                  )
                "
                v-model="scope.row.classification_status"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="1"
                :inactive-value="-1"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <el-tooltip
                class="item"
                effect="dark"
                content="查看分类详情"
                placement="top-start"
              >
                <el-button icon="el-icon-search" @click="lookBtnClassList(scope.row)"></el-button>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                content="修改分类"
                placement="top-start"
              >
                <el-button
                  icon="el-icon-edit"
                  type="primary"
                  @click="updataBtnClassList(scope.row)"
                ></el-button>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                content="删除分类"
                placement="top-start"
              >
                <el-button
                  icon="el-icon-delete"
                  type="danger"
                  @click="delClassList(scope.row.classification_id)"
                ></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          @current-change="pageChange"
          @size-change="sizeChange"
          :page-sizes="[7, 15, 20]"
          layout="total, sizes, prev, pager, next"
          :page-size="this.getInfo.page_num"
          background
          :total="exp.num"
        >
        </el-pagination>
      </el-card>
      <!-- 增加场地服务标题 -->
      <el-dialog
        :visible.sync="dialogFormVisible"
        @close="userClose(form)"
        :append-to-body="true"
      >
        <div slot="title" v-if="updataFalg" class="dialog-footer">
          <div class="title">修改分类</div>
        </div>
        <div slot="title" v-else-if="lookFalg" class="dialog-footer">
          <div class="title">查看分类</div>
        </div>
        <div slot="title" v-else class="dialog-footer">
          <div class="title">增加分类</div>
        </div>
        <div class="diaBox">
          <el-form :model="form" ref="ruleForm" :rules="rules">
            <el-form-item label="分类名称" :label-width="formLabelWidth" prop="className">
              <el-input v-model="form.classification_name"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button v-if="addFlag" type="primary" @click="addClassDia('ruleForm')">添 加</el-button>
          <el-button
            v-else-if="lookFalg"
            type="primary"
            @click="dialogFormVisible = false"
            >确 认</el-button
          >
          <el-button v-else type="primary" @click="updateClassDia('ruleForm')">修 改</el-button>
        </div>
      </el-dialog>
    </div>
  </template>
    
    <script>
  //这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
  //例如：import 《组件名称》 from '《组件路径》';
  import { 
      PostClassList, 
      PostClassAdd,
      PostClassIshelf,
      PostClassSave,
      PostClassDelete
   } from "@/api/classList/classList.js";
  export default {
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
      var validateClassType = (rule, value, callback) => {
        if (this.form.classification_type === "") {
          callback(new Error("请选择分类类型"));
        } else {
          callback();
        }
      };
      var validateClassName = (rule, value, callback) => {
        if (this.form.classification_name === "") {
          callback(new Error("请输入分类名称"));
        } else {
          callback();
        }
      };
      var validateClassOpen = (rule, value, callback) => {
        if (this.form.classification_icon_open === "") {
          callback(new Error("请上传选中图标"));
        } else {
          callback();
        }
      };
      var validateClassClose = (rule, value, callback) => {
        if (this.form.classification_icon_close === "") {
          callback(new Error("请上传未选中图标"));
        } else {
          callback();
        }
      };
      //这里存放数据
      return {
        rules: {
          classType: [{ validator: validateClassType, trigger: "blur" }],
          className: [{ validator: validateClassName, trigger: "blur" }],
          ClassOpen: [{ validator: validateClassOpen, trigger: "blur" }],
          classClose: [{ validator: validateClassClose, trigger: "blur" }],
        },
        //分类数据
        optionsClassList:[
          {
          value: 1,
            label: '文章'
        },
        {
          value: 2,
            label: '美食'
        },
        {
          value: 3,
            label: '住宿'
        },
        {
          value: 4,
            label: '学院'
        },
        {
          value: 5,
            label: '路线'
        },
      ],
      maxLimit:1,
        //总条数
        exp: {
          count: Number,
          num: 0,
        },
        //当前页码
        getInfo: {
          //搜索框
          info: "",
          page_code: 1,
          page_num: 15,
        },
        optionsClassList:[
          {
              value: '1',
            label: '文章'
          },
          {
              value: '2',
            label: '美食'
          },
          {
              value: '3',
            label: '住宿'
          },
          {
              value: '4',
            label: '学院'
          },
          {
              value: '5',
            label: '路线'
          },
        ],
        classification_type:1,
        form: {
          classification_id:'',
          classification_type: '1',//分类类型
          classification_name:"",//分类名称
          classification_icon_open:'',//选中图标
          classification_icon_close:'',//未选中图标
        },
        props: {
          children: "sub",
          value: "id",
          label: "name",
        },
        //表格数据
        tableData: [],
        token: {
          token: window.sessionStorage.getItem("token"),
        },
        formLabelWidth: "100px",
        imgParam: {
          path: "contract/template",
        },
        fileList: [],
        fileListClose:[],
        //控制弹窗--新增修改
        dialogFormVisible: false,
        addFlag: false,
        lookFalg: false,
        updataFalg: false,
      };
    },
    //监听属性 类似于data概念
    computed: {
      imgUrl() {
        return this.$store.state.imgUrl;
      },
    },
    //监控data中的数据变化
    watch: {
      dialogFormVisible(newVal, oldVal) {
        if (!newVal) {
          this.form = this.$options.data().form;
          this.fileList=[];
          this.fileListClose=[];
          this.updataFalg = false;
          this.lookFalg = false;
          this.addFlag = false;
        }
      },
    },
    //方法集合
    methods: {
      //分类列表下拉框更新
      onChangeClassList(value){
        var data = {
        classification_type: value, //分类类型 1:文章 2:美食 3:住宿 4:学院
      };
      this.classification_type=value;
      this.PostClassList(data);
      },
      //查看分类按钮事件
      lookBtnClassList(item){
          this.dialogFormVisible=true;
         this.lookFalg=true;
         this.form={
          classification_id:item.classification_id,
          classification_type:item.classification_type,
          classification_name:item.classification_name
         }
      },
      //修改分类按钮事件  
      updataBtnClassList(item){
         this.dialogFormVisible=true;
         this.updataFalg=true;
         this.form={
          classification_id:item.classification_id,
        //   classification_type:item.classification_type.toString(),
          classification_name:item.classification_name,
        //   classification_icon_open:item.classification_icon_open,
        //   classification_icon_close:item.classification_icon_close,
         }
        //  this.fileList=[{
        //   name:'',
        //   url:item.classification_icon_open,
        //  }]
        //  this.fileListClose=[{
        //   name:'',
        //   url:item.classification_icon_close,
        //  }]
        //  this.classification_type=item.classification_type;
      },
        //删除分类
        delClassList(classification_id) {
        this.$confirm("此操作将删除该数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            var objData = {
              classification_id: classification_id,
            };
            this.PostClassDelete(objData);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      },
      //分类类型select选择值改变事件
      changeClassType(value){
         console.log(value)
         this.classification_type=value;
      },
      //dialog修改按钮事件
      updateClassDia(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.PostClassSave(this.form)
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },
      //dialog增加按钮事件
      addClassDia(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.PostClassAdd(this.form)
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },
          //添加事件 退出弹窗
      userClose(e) {
        this.form = {
          route_cover_image: "", //图片
        };
        (this.fileList = []), console.log("close");
      },
      //点击增加分类按钮事件
      addClassBtn(){
         this.dialogFormVisible=true;
         this.addFlag=true;
      },
      //swith状态事件
      async changeState(id, status) {
        console.log(id, status);
          var data = {
            classification_id: id,
            classification_status: status,
          };
          let res = await PostClassIshelf(data);
          if (res.data.status) {
            this.$message({
              type: "success",
              message: "更新成功!",
            });
            var data = {
              page: this.getInfo.page_code,
              page_num: this.getInfo.page_num,
            };
            this.PostClassList(data);
          } else {
            this.$message.error("错了哦，这是一条错误消息");
          }
      },
      //修改每页数量
      sizeChange(value) {
        this.getInfo.page_num = value;
        var data = {
          page: this.getInfo.page_code,
          list_rows: this.getInfo.page_num,
        };
        console.log(data);
        this.tableData = [];
         this.PostArticleList(data);
      },
      //分页切换
      pageChange(value) {
        this.getInfo.page_code = value;
        var data = {
          page: this.getInfo.page_code,
          list_rows: this.getInfo.page_num,
        };
        console.log(data);
        this.tableData = [];
        this.PostArticleList(data);
      },
      exceed() {
        this.$message({
          message: "数量超出",
          type: "warning",
        });
      },
      //导入选中图标事件
      imgSuccess(response, file, fileList) {
         console.log(response)
        this.form.classification_icon_open = response.result.url;
      },
    //导入未选中事件
      imgSuccessClose(response, file, fileList) {
        // console.log(response)
        this.form.classification_icon_close = response.result.url;
      },
  
      //移除选中图标的事件
      imgRemove(file, fileList) {
        this.form.classification_icon_close = "";
      },
       //移除未选中的事件
       imgRemoveClose(file, fileList) {
        this.form.classification_icon_close = "";
      },
      /**-----------------网络请求生命周期函数--------------------- */
      //删除分类
      async PostClassDelete(objData) {
        let res = await PostClassDelete(objData);
        if (res.data.status) {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          var data = {            
        classification_type: this.classification_type, //分类类型 1:文章 2:美食 3:住宿 4:学院
      };
      this.PostClassList(data);
        } else {
          this.$message({
            type: "success",
            message: res.data.message,
          });
        }
      },
      //修改分类
      async PostClassSave(dataObj){
          console.log(dataObj)
          let res=await PostClassSave(dataObj);
          if(res.data.status){
              this.$message({
            type: "success",
            message: res.data.message,
          });
          this.dialogFormVisible=false;
          var data = {
        classification_type: this.classification_type, //分类类型 1:文章 2:美食 3:住宿 4:学院
      };
      this.PostClassList(data);
          }else{
              this.$message.error( res.data.message);
          }
          console.log(res.data);
      },
      //增加分类
       async PostClassAdd(dataObj){
          console.log(dataObj)
          let res=await PostClassAdd(dataObj);
          if(res.data.status){
              this.$message({
            type: "success",
            message: res.data.message,
          });
          this.dialogFormVisible=false;
          var data = {
        classification_type: this.classification_type, //分类类型 1:文章 2:美食 3:住宿 4:学院
      };
      this.PostClassList(data);
          }else{
              this.$message.error( res.data.message);
          }
          console.log(res.data);
      },
      //分类列表
      async PostClassList(data) {
        let res = await PostClassList(data);
        this.tableData = res.data.result.data;
          this.getInfo = {
            page_code: res.data.result.last_page,
            list_rows: res.data.result.per_page,
          };
          // this.form.class
          this.exp.num = res.data.result.total;
      },
    },
    beforeCreate() {}, //生命周期 - 创建之前
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
      var data = {
        classification_type: this.classification_type, //分类类型 1:文章 2:美食 3:住宿 4:学院
      };
      this.PostClassList(data);
    },
  
    beforeMount() {}, //生命周期 - 挂载之前
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {},
    beforeUpdate() {}, //生命周期 - 更新之前
    updated() {}, //生命周期 - 更新之后
    activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    beforeDestroy() {}, //生命周期 - 销毁之前
    destroyed() {}, //生命周期 - 销毁完成
  };
  </script>
    
    <style scoped>
  .page {
    margin-top: 20px;
  }
  .title {
    font-size: 18px;
    font-weight: bold;
  }
  .set-page {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .set-page-left {
    color: #0000006d;
    font-size: 14px;
    font-weight: 400;
  }
  .title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
  .diaBox {
    display: flex;
    justify-content: center;
  }
  .imgUrl {
    margin-left: 50%;
    transform: translateX(-50%);
    width: 250px;
    height: 150px;
    overflow: hidden;
    text-align: center;
  }
  </style>