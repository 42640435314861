import {post} from '@/utils/request'

//分类列表
export function PostClassList(data) {
  return post('adminapi/classification_list',data)
}
//分类增加
export function PostClassAdd(data) {
    return post('adminapi/classification_add',data)
  }
  //分类上下架
export function PostClassIshelf(data) {
    return post('adminapi/classification_shelf',data)
  }
    //分类更新
export function PostClassSave(data) {
    return post('adminapi/classification_save',data)
  }
      //分类删除    
export function PostClassDelete(data) {
    return post('adminapi/classification_delete',data)
  }